import React from "react";

const Electrical = () => {
    return (
        <div className="content">
            <h1>Проектирование электроснабжения</h1>
            <p>Проектирование электроснабжения – важный этап создания эффективной и безопасной энергетической системы для вашего объекта. Наши специалисты обладают глубокими знаниями и опытом в этой области, что позволяет нам разрабатывать надежные и эффективные решения.</p>
            <p>Мы предоставляем услуги проектирования электроснабжения для объектов различной сложности и назначения, от жилых зданий до промышленных предприятий. Наши проекты соответствуют всем действующим стандартам и нормативам, что обеспечивает долгосрочную и безопасную эксплуатацию энергетических систем.</p>

            <h2>Что включает проектирование электроснабжения</h2>
            <p>Наши услуги по проектированию электроснабжения включают в себя:</p>
            <ul>
                <li>Анализ потребностей и разработка концепции электроснабжения.</li>
                <li>Разработка схем электрических сетей и выбор оборудования.</li>
                <li>Проектирование систем заземления и молниезащиты.</li>
                <li>Подготовка проектной документации и ее согласование.</li>
                <li>Проведение расчетов электрических нагрузок и выбор кабелей.</li>
            </ul>
            <p>Мы предоставляем полный комплекс услуг, начиная от анализа потребностей и заканчивая подготовкой проектной документации и ее согласованием. Наши специалисты работают с современными инструментами и технологиями, что позволяет нам разрабатывать высокоэффективные и надежные решения.</p>
            <div className="image-container">
                <img src="/images/electrical-blueprint.png" alt="Electrical Blueprint" className="image"/>
            </div>
            <h2>Этапы проектирования электроснабжения</h2>
            <p>Процесс проектирования включает несколько ключевых этапов:</p>
            <ul>
                <li>Первоначальный анализ и составление технического задания.</li>
                <li>Разработка концептуальных и рабочих схем электроснабжения.</li>
                <li>Выбор оборудования и материалов.</li>
                <li>Согласование проекта с контролирующими органами.</li>
                <li>Подготовка окончательной документации и сдача проекта.</li>
            </ul>
            <p>Стоимость услуг по проектированию электроснабжения зависит от объема и сложности работ. Для получения подробной информации и расчета стоимости проекта, свяжитесь с нами по телефону или электронной почте.</p>
        </div>
    );
}

export default Electrical;
