
export const serviceItems = [
    {
        imagePath: '/images/service-images/engineer-system-service.jpg',
        title: 'Обслуживание инженерных систем',
        path: ''
    },
    {
        imagePath: '/images/service-images/projecting-engineer-system.jpg',
        title: 'Проектирование инженерных сетей',
        path: ''
    },
    {
        imagePath: '/images/service-images/repair.jpg',
        title: 'Ремонт инженерных сетей',
        path: ''
    },
    {
        imagePath: '/images/service-images/sewerage.jpg',
        title: 'Монтаж систем канализации',
        path: ''
    },
    {
        imagePath: '/images/service-images/water-supply.jpg',
        title: 'Монтаж водоснабжения',
        path: ''
    },
    {
        imagePath: '/images/service-images/heating.jpg',
        title: 'Монтаж отопления',
        path: ''
    }
]
