import './Dropdown.css';
import {NavLink} from "react-router-dom";

function Dropdown({ submenus, closeMobileMenu }) {
    return (
        <ul className={"dropdown-menu"} >
            {submenus.map((submenu, index) => (
                <li key={index} className="dropdown-link" onClick={closeMobileMenu}>
                    <NavLink to={submenu.path}>{submenu.title} </NavLink>
                </li>
            ))}
        </ul>
    );
}

export default Dropdown;