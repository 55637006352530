import './Navbar.css';
import {NavLink} from 'react-router-dom'
import {FiPhone} from "react-icons/fi";
import {useState} from "react";
import {MdEngineering} from "react-icons/md";
import {FaBars, FaTimes} from "react-icons/fa";
import MenuItems, {menuItemsData} from "../items/MenuItems";

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <nav className="navbar">
            <div className="navbar-container container">
                <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <MdEngineering className="navbar-icon"/>
                </NavLink>
                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes/> : <FaBars/>}
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    {menuItemsData.map((menu, index) => {
                        return <MenuItems items={menu} key={index} closeMobileMenu={closeMobileMenu} />;
                    })}
                    <CustomLink to="tel:+78122345678" onClick={closeMobileMenu}><FiPhone className="phone-icon" />  8 (911) 926-67-34</CustomLink>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;


function CustomLink({to, children, ...props}) {
    return (
        <li className={"nav-item"} >
            <NavLink className="nav-links" to={to} {...props}>
                {children}
            </NavLink>
        </li>
    )
}