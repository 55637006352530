import React from "react";

export const VentilationMontage = () => {
    return (
        <div className="content">
            <h1>Монтаж систем вентиляции</h1>
            <p>Системы вентиляции играют ключевую роль в обеспечении комфортного и здорового микроклимата в помещениях.
                Качественно спроектированная и установленная система вентиляции гарантирует поступление свежего воздуха,
                эффективное удаление загрязнений и регулирование уровня влажности.</p>
            <p>Наша компания предлагает профессиональные услуги по монтажу систем вентиляции для различных типов
                объектов.</p>

            <h2>Наши услуги</h2>
            <p>Мы предоставляем полный спектр услуг по монтажу систем вентиляции, включая:</p>
            <ul>
                <li>Проектирование систем вентиляции: Разработка индивидуального проекта вентиляции с учетом специфики
                    помещения и требований заказчика.
                </li>
                <li>Подбор оборудования: Помощь в выборе вентиляционного оборудования, соответствующего всем нормам и
                    стандартам.
                </li>
                <li>Монтаж систем вентиляции: Установка вентиляционных каналов, вентиляторов, фильтров, кондиционеров и
                    других элементов системы.
                </li>
                <li>Пуско-наладочные работы: Проверка и настройка системы для обеспечения ее максимальной эффективности
                    и надежности.
                </li>
                <li>Техническое обслуживание: Регулярное обслуживание и контроль работоспособности системы вентиляции
                    для предотвращения поломок и поддержания ее эффективности.
                </li>

            </ul>
            <p>Мы предоставляем полный комплекс услуг, начиная от анализа потребностей и заканчивая подготовкой
                проектной документации и ее согласованием. Наши специалисты работают с современными инструментами и
                технологиями, что позволяет нам разрабатывать высокоэффективные и надежные решения.</p>
            <div className="image-container">
                <img src="/images/ventilation-blueprint.png" alt="Ventilation Blueprint" className="image"/>
            </div>
            <h2>Преимущества наших услуг</h2>
            <ul>
                <li>Опытные специалисты: Наша команда состоит из квалифицированных инженеров и монтажников с многолетним
                    опытом работы.
                </li>
                <li>Индивидуальный подход: Мы учитываем все пожелания клиента и особенности объекта при проектировании и
                    монтаже системы вентиляции.
                </li>
                <li>Качество и надежность: Используем только проверенные материалы и оборудование от ведущих
                    производителей.
                </li>
                <li>Энергоэффективность: Мы устанавливаем системы, которые не только обеспечивают оптимальный
                    микроклимат, но и помогают экономить энергию.
                </li>
                <li>Полный цикл обслуживания: Предоставляем все виды услуг от проектирования до регулярного технического
                    обслуживания.
                </li>
            </ul>

            <h2>Процесс монтажа систем вентиляции</h2>
            <ul>
                <li>Консультация и оценка объекта: Наши специалисты проводят детальный осмотр объекта и обсуждают с
                    клиентом все требования и пожелания.
                </li>
                <li>Проектирование системы: Разработка детального проекта системы вентиляции с учетом всех технических и
                    эксплуатационных требований.
                </li>
                <li>Подбор оборудования: Рекомендации по выбору оборудования, которое наилучшим образом подходит для
                    вашего объекта.
                </li>
                <li>Монтажные работы: Установка всех компонентов системы вентиляции, включая каналы, вентиляторы,
                    кондиционеры и фильтры.
                </li>
                <li>Пуско-наладочные работы: Тщательная проверка и настройка системы для обеспечения ее надежной и
                    эффективной работы.
                </li>
                <li>Сдача объекта: Передача системы в эксплуатацию с предоставлением всей необходимой документации и
                    инструкций по эксплуатации.
                </li>
                <li>Техническое обслуживание: Регулярные проверки и обслуживание системы для поддержания ее
                    долговечности и эффективности.
                </li>
            </ul>

            <h2>Почему выбирают нас?</h2>
            <ul>
                <li>Профессионализм и опыт: Более 10 лет успешной работы в сфере монтажа систем вентиляции.</li>
                <li>Высокое качество работ: Использование только сертифицированных материалов и оборудования.</li>
                <li>Комплексный подход: Предоставляем полный цикл услуг от проектирования до обслуживания.</li>
                <li>Индивидуальный подход: Учитываем все пожелания клиента и особенности объекта.</li>
                <li>Прозрачные цены: Обеспечиваем конкурентные и прозрачные условия ценообразования на все наши услуги.
                </li>
            </ul>
        </div>
    );
}