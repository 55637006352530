import React from 'react';
import './InfoBlock.css';

const InfoBlock = () => {
    return (
        <div className="info-container">
            <div className="info-item">
                <div className="info-number">1</div>
                <div className="info-text content">
                    <p>Можно всё сделать самому. С технической стороны сложно тут ничего нет.</p>
                    <p>Но, как и в любой инженерной системе, тут есть очень много тонкостей, если их упустить, потом в процессе эксплуатации могут создаться определённые проблемы, которые можно будет исправить, но это будет требовать финансовых затрат, а ещё хуже если придётся вносить изменения затронув чистовую отделку вашего жилья.</p>
                </div>
            </div>
            <div className="info-item">
                <div className="info-number">2</div>
                <div className="info-text content">
                    <p>Обратиться к частнику по объявлению или, что гораздо лучше, по рекомендации знакомых или друзей. Но тут уже как повезёт.</p>
                    <p>Тут можно рассчитывать только на профессионализм, порядочность и честность монтажника. В противном случае спросить будет не с кого.</p>
                </div>
            </div>
            <div className="info-item">
                <div className="info-number">3</div>
                <div className="info-text content">
                    <p>Обратиться в фирму. Но и тут можно частично столкнуться с вариантом номер два. Поэтому, чтобы обезопасить вас от лишних проблем, мы принимаем на работу квалифицированных монтажников, за которых можем смело давать гарантию на выполненные работы 5 лет. Наши специалисты периодически проходят обучение по тем или иным направлениям в Академии Водопад, лицензированной в комитете по образованию.</p>
                    <p>Плюс ко всему, имея 11-ти летний опыт в этой сфере, мы стремимся к наивысшим результатам. Спасибо за обращение в нашу компанию ведь с каждым новым клиентом мы стараемся совершенствоваться и развивать свои способности.</p>
                </div>
            </div>
        </div>
    );
}

export default InfoBlock;