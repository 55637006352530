import './MainPage.css';
import Navbar from "../components/navbar";
import Content from "../content/Content";
import Footer from "../components/footer/Footer";

function MainPage() {
    return (
        <>
            <Navbar/>
            <div className="main-container">
                <Content/>
            </div>
            <div className="footer">
                <Footer/>
            </div>
        </>
    );
}

export default MainPage;
