import React from "react";
import {Route, Routes} from "react-router-dom";
import './Content.css';

import Contacts from "../pages/contacts/Contacts";
import Home from "../pages/home/Home";
import {engineerItems, montageItems} from "../components/items/SideBarItems";
import ContactsData from "../pages/contacts/ContactsData";
import {EngineeringDesign} from "../pages/mainTemplate/EngineeringDesign";
import {Sidebar} from "../components/sidebar/Sidebar";
import {Boiler} from "../pages/boiler/Boiler";
import {VentilationMontage} from "../pages/montage/ventilationMontage/VentilationMontage";
import {ElectricalMontage} from "../pages/montage/electricalMontage/ElectricalMontage";
import {WaterSupplyMontage} from "../pages/montage/waterSupplyMontage/WaterSupplyMontage";
import {HeatingMontage} from "../pages/montage/heatingMontage/HeatingMontage";
import ContactsMap from "../pages/contacts/ContactMap";
import BoilerData from "../pages/boiler/BoilerData";
import Engineering from "../pages/projecting/engineering/Engineering";
import Electrical from "../pages/projecting/electrical/Electrical";
import Ventilation from "../pages/projecting/ventilation/Ventilation";


function Content() {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home/>}></Route>
                <Route path="/engineering-system" element={
                    <EngineeringDesign
                        background="engineering-header header"
                        title="Проектирование инженерных систем"
                        children={<Engineering/>}
                        sidebar={<Sidebar items={engineerItems}/>}/>}>
                </Route>
                <Route path="/electrical-system" element={
                    <EngineeringDesign
                        background="electrical-header header"
                        title="Проектирование электроснабжения"
                        children={<Electrical/>}
                        sidebar={<Sidebar items={engineerItems}/>}/>}>
                </Route>
                <Route path="/ventilation-system" element={
                    <EngineeringDesign
                        background="ventilation-header header"
                        title="Проектирование систем вентиляции"
                        children={<Ventilation/>}
                        sidebar={<Sidebar items={engineerItems}/>}/>}>
                </Route>
                <Route path="/heating" element={
                    <EngineeringDesign
                        background="heating-montage-header header"
                        title="Отопление"
                        children={<HeatingMontage/>}
                        sidebar={<Sidebar items={montageItems}/>}/>}>
                </Route>
                <Route path="/water-supply" element={
                    <EngineeringDesign
                        background="water-supply-montage-header header"
                        title="Водоснабжение и канализация"
                        children={<WaterSupplyMontage/>}
                        sidebar={<Sidebar items={montageItems}/>}/>}>
                </Route>
                <Route path="/electrician" element={
                    <EngineeringDesign
                        background="electrical-montage-header header"
                        title="Электрика"
                        children={<ElectricalMontage/>}
                        sidebar={<Sidebar items={montageItems}/>}/>}>
                </Route>
                <Route path="/ventilation" element={
                    <EngineeringDesign
                        background="ventilation-montage-header header"
                        title="Вентиляция"
                        children={<VentilationMontage/>}
                        sidebar={<Sidebar items={montageItems}/>}/>}>
                </Route>
                <Route path="/boiler" element={<Boiler
                    background="boiler-header header"
                    title="Обслуживание и ремонт котлов"
                    children={<BoilerData/>}/>}>
                </Route>
                <Route path="/contacts" element={<Contacts
                    background="contacts-header header"
                    title="Контакты"
                    children={<ContactsData/>}
                    map={<ContactsMap/>}/>}>
                </Route>
            </Routes>
        </>
    );
}

export default Content;