import React from "react";

export const HeatingMontage = () => {
    return (
        <div className="content">
            <h1>Монтаж отопления</h1>
            <p>Правильно спроектированная и установленная система отопления является основой комфорта и уюта в вашем
                доме или офисе. Качественный монтаж отопления обеспечивает эффективную работу системы, экономию
                энергоресурсов и долговечность оборудования.</p>

            <h2>Наши услуги</h2>
            <p>Мы предлагаем полный комплекс услуг по монтажу отопления:</p>
            <ul>
                <li>Проектирование системы отопления: Разработка индивидуального проекта с учетом всех особенностей
                    помещения и пожеланий клиента.
                </li>
                <li>Подбор оборудования: Помощь в выборе котлов, радиаторов, труб и других компонентов системы отопления
                    от ведущих производителей.
                </li>
                <li>Монтаж: Профессиональная установка всех элементов системы отопления, включая котлы, радиаторы,
                    трубы, термостаты и насосы.
                </li>
                <li>Пуско-наладочные работы: Проверка и настройка системы для достижения оптимальной
                    производительности.
                </li>
                <li>Техническое обслуживание: Регулярное обслуживание системы для предотвращения поломок и поддержания
                    ее эффективности.
                </li>

            </ul>
            <h2>Преимущества наших услуг</h2>
            <ul>
                <li>Квалифицированные специалисты: Наши инженеры и монтажники имеют многолетний опыт и высокую
                    квалификацию.
                </li>
                <li>Индивидуальный подход: Разработка проекта и подбор оборудования с учетом особенностей вашего
                    объекта.
                </li>
                <li>Гарантия качества: Использование только проверенных и сертифицированных материалов и оборудования.
                </li>
                <li>Энергосбережение: Установка систем, которые обеспечивают максимальную энергоэффективность и
                    минимальные эксплуатационные расходы.
                </li>
                <li>Комплексное обслуживание: Предоставление полного спектра услуг от проектирования до технического
                    обслуживания.
                </li>

            </ul>

            <h2>Процесс монтажа отопления</h2>
            <ul>
                <li>Консультация и оценка объекта: Наш специалист проводит осмотр объекта и обсуждает с клиентом все
                    требования и пожелания.
                </li>
                <li>Проектирование системы: Разработка детального проекта системы отопления с учетом всех технических и
                    экономических аспектов.
                </li>
                <li>Подбор оборудования: Рекомендации по выбору оборудования и материалов, которые наилучшим образом
                    соответствуют потребностям клиента.
                </li>
                <li>Монтажные работы: Установка котлов, радиаторов, трубопроводов и других элементов системы
                    отопления.
                </li>
                <li>Пуско-наладочные работы: Проверка системы на герметичность и работоспособность, настройка параметров
                    для оптимальной работы.
                </li>
                <li>Сдача объекта: Передача системы отопления в эксплуатацию с предоставлением всех необходимых
                    документов и инструкций.
                </li>
                <li>Техническое обслуживание: Регулярные проверки и обслуживание системы для поддержания ее
                    эффективности и надежности.
                </li>

            </ul>

            <h2>Почему выбирают нас?</h2>
            <ul>
                <li>Опыт и надежность: Более 10 лет успешной работы в сфере монтажа отопления.</li>
                <li>Высокое качество: Использование только сертифицированных материалов и оборудования.</li>
                <li>Комплексный подход: Полный цикл услуг от проектирования до обслуживания.</li>
                <li>Индивидуальный подход: Учет всех пожеланий клиента и особенностей объекта.</li>
                <li>Конкурентные цены: Прозрачное ценообразование и доступные тарифы на услуги.</li>

            </ul>
        </div>
    );
}