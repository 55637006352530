import './Home.css';
import {Link} from "react-router-dom";
import {RiArrowRightWideLine} from "react-icons/ri";
import {MdOutlineHighQuality} from "react-icons/md";
import {BiCheckShield, BiTimer} from "react-icons/bi";
import ProjectSlider from "../../components/slider/ProjectSlider";
import SpecialistChoice from "../../components/specialistChoice/SpecialistChoice";
import InfoBlock from "../../components/infoBlock/InfoBlock";
import Service from "../../components/service/Service";
import {serviceItems} from "../../components/items/ServiceItems";
import React from "react";

function Home() {
    return (
        <>
            <div className="present-container">
                <div className="text-container">
                    <h1>Монтаж инженерных систем</h1>
                    <h3>в Санкт-Петербурге</h3>
                    <Link className="more-btn" to='/boiler'>
                        Узнать больше <div className="more-arrow-container"><RiArrowRightWideLine
                        className="more-arrow"/></div>
                    </Link>
                </div>
            </div>

            <section className="features-container">
                <main className="features main-width">
                    <div className="feature">
                        <span className="feature-title">Качество</span>
                        <BiCheckShield className='feature-icon'/>
                        <p>Гарантируем качество наших работ на всех этапах</p>
                    </div>
                    <div className="feature">
                        <span className="feature-title">Профессионализм</span>
                        <MdOutlineHighQuality className='feature-icon'/>
                        <p>Команда квалифицированных инженеров</p>
                    </div>
                    <div className="feature">
                        <span className="feature-title">Соблюдение сроков</span>
                        <BiTimer className='feature-icon'/>
                        <p>Всегда выполняем работы в срок, указанный в договоре</p>
                    </div>
                </main>
            </section>

            <section className="services">
                <main className="main-width">
                    <div className="main-title">
                        <h2>Наши услуги</h2>
                    </div>
                    <div className="services-container">
                        {serviceItems.map((items, index) => {
                            return <Service key={index} imagePath={items.imagePath} title={items.title} path={items.path}/>;
                        })}
                    </div>
                </main>
            </section>
            <section className="projects">
                <main className="main-width">
                    <div className="main-title">
                        <h2>Готовые проекты</h2>
                    </div>
                    <main>
                        <ProjectSlider/>
                    </main>
                </main>
            </section>
            <section className="choices">
                <main className="main-width">
                    <div className="main-title">
                        <h2>Выбор специалиста</h2>
                    </div>
                    <SpecialistChoice/>
                </main>
            </section>
            <section className="info">
                <main className="main-width">
                    <InfoBlock/>
                </main>
            </section>
        </>
    );
}

export default Home;
