import './Footer.css';
import {Link} from "react-router-dom";
import {FiPhone} from "react-icons/fi";
import {IoMailOutline} from "react-icons/io5";

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-column">
                    <h3>Компания</h3>
                    <ul>
                        <li><Link to="/contacts">О компании</Link></li>
                        <li><Link to="/contacts">Лицензии</Link></li>
                        <li><Link to="/contacts">Политика конфиденциальности</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Услуги</h3>
                    <ul>
                        <li><Link to="/engineering-system">Проектирование инженерных систем</Link></li>
                        <li><Link to="/electrical-system">Проектирование электроснабжения</Link></li>
                        <li><Link to="/ventilation-system">Проектирование систем вентиляции</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Инженерные сети</h3>
                    <ul>
                        <li><Link to="/water-supply">Водоснабжение</Link></li>
                        <li><Link to="/electrician">Электрика</Link></li>
                        <li><Link to="/heating">Отопление</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Контакты</h3>
                    <ul>
                        <li><FiPhone /> <a href="tel:+79119266734">8 (911) 926-67-34</a></li>
                        <li><IoMailOutline /> <a href="mailto:spb@tmontazh.ru">spb@tmontazh.ru</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;