import './Boiler.css';
import React from "react";

export const Boiler = ({background, title, children}) => {
    return (
        <div className="boiler-container">
            <div className={background}>
                <main className="page-top">
                    <h1 className="page-title">{title}</h1>
                </main>
            </div>
            <main className="boiler-max-width">
                <div className="boiler-content-container">
                    <div className="boiler-content boiler-right_block">
                        {children}
                    </div>
                </div>
            </main>
        </div>
    );
}
