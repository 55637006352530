import React from "react";

export const WaterSupplyMontage = () => {
    return (
        <div className="content">
            <h1>Монтаж водоснабжения и канализации</h1>
            <p>Системы водоснабжения и канализации являются неотъемлемой частью любого жилого или коммерческого объекта.
                Качественный монтаж этих систем обеспечивает надежное водоснабжение, эффективный отвод сточных вод и
                долговечность инженерных коммуникаций.</p>

            <h2>Наши услуги</h2>
            <p>Мы предлагаем полный комплекс услуг по монтажу водоснабжения и канализации:</p>
            <ul>
                <li>Проектирование систем: Разработка индивидуального проекта водоснабжения и канализации с учетом
                    особенностей объекта и пожеланий клиента.
                </li>
                <li>Подбор оборудования и материалов: Помощь в выборе труб, насосов, фильтров и других компонентов
                    системы от ведущих производителей.
                </li>
                <li>Монтаж водоснабжения: Установка всех элементов системы водоснабжения, включая трубопроводы, насосы,
                    фильтры и водонагреватели.
                </li>
                <li>Монтаж канализации: Установка стояков, труб, коллекторов и других элементов системы канализации.
                </li>
                <li>Пуско-наладочные работы: Проверка и настройка системы для обеспечения ее эффективной и безопасной
                    работы.
                </li>
                <li>Техническое обслуживание: Регулярное обслуживание систем водоснабжения и канализации для
                    предотвращения поломок и поддержания их эффективности.
                </li>
            </ul>

            <h2>Преимущества наших услуг</h2>
            <ul>
                <li>Профессионализм: Наши специалисты обладают высокой квалификацией и многолетним опытом в монтаже
                    систем водоснабжения и канализации.
                </li>
                <li>Индивидуальный подход: Разработка проекта и подбор оборудования с учетом особенностей вашего объекта
                    и ваших потребностей.
                </li>
                <li>Качество и надежность: Использование сертифицированных материалов и оборудования от ведущих
                    производителей.
                </li>
                <li>Эффективность и экономия: Установка систем, обеспечивающих максимальную эффективность и
                    экономичность.
                </li>
                <li>Комплексное обслуживание: Полный цикл услуг от проектирования до технического обслуживания.</li>
            </ul>

            <h2>Процесс монтажа водоснабжения и канализации</h2>
            <ul>
                <li>Консультация и оценка объекта: Наш специалист проводит осмотр объекта и обсуждает с клиентом все
                    требования и пожелания.
                </li>
                <li>Проектирование системы: Разработка детального проекта системы водоснабжения и канализации с учетом
                    всех технических и экономических аспектов.
                </li>
                <li>Подбор оборудования и материалов: Рекомендации по выбору оборудования и материалов, которые
                    наилучшим образом соответствуют потребностям клиента.
                </li>
                <li>Монтажные работы: Установка трубопроводов, насосов, фильтров, стояков и других элементов системы
                    водоснабжения и канализации.
                </li>
                <li>Пуско-наладочные работы: Проверка системы на герметичность и работоспособность, настройка параметров
                    для оптимальной работы.
                </li>
                <li>Сдача объекта: Передача системы водоснабжения и канализации в эксплуатацию с предоставлением всех
                    необходимых документов и инструкций.
                </li>
                <li>Техническое обслуживание: Регулярные проверки и обслуживание систем для поддержания их эффективности
                    и надежности.
                </li>
            </ul>

            <h2>Почему выбирают нас?</h2>
            <ul>
                <li>Опыт и надежность: Более 10 лет успешной работы в сфере монтажа водоснабжения и канализации.</li>
                <li>Высокое качество: Использование только сертифицированных материалов и оборудования.</li>
                <li>Комплексный подход: Полный цикл услуг от проектирования до обслуживания.</li>
                <li>Индивидуальный подход: Учет всех пожеланий клиента и особенностей объекта.</li>
                <li>Конкурентные цены: Прозрачное ценообразование и доступные тарифы на услуги.</li>
            </ul>
        </div>
    );
}