import {Carousel} from "antd";
import {Link} from "react-router-dom";
import './ProjectSlider.css';

const ProjectSlider = () => {
    const settings = {
        showThumbs: true,
        infiniteLoop: true,
        useKeyboardArrows: true,
        autoPlay: true,
        showStatus: false
    };

    const projects = [
        {
            id: 1,
            image: '/images/done-project.png',
            description: 'Готовый проект 1'
        },
        {
            id: 2,
            image: '/images/done-project.png',
            description: 'Готовый проект 2'
        },
        {
            id: 3,
            image: '/images/done-project.png',
            description: 'Готовый проект 3'
        }
    ];

    return (
        <section className="projects-slider">
            <Carousel {...settings}>
                {projects.map(project => (
                    <div key={project.id} className="slide">
                        <img src={project.image} alt={project.description} />
                        <Link to="/" className="btn">Узнать об услуге</Link>
                    </div>
                ))}
            </Carousel>
            <Link to="/" className="all-projects">Все проекты</Link>
        </section>
    );
};

export default ProjectSlider;