import React from "react";

function ContactsMap() {
    return (
        <div className="map-container">
            <div className="contact-map">
                <a className="map-city"
                   href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps">Санкт‑Петербург</a>
                <a className="map-address"
                   href="https://yandex.ru/maps/2/saint-petersburg/house/leninskiy_prospekt_151/Z0kYdQdgQE0EQFtjfXR0cHhhZg==/?ll=30.300871%2C59.851457&utm_medium=mapframe&utm_source=maps&z=16.98">Ленинский
                    проспект, 151 —
                    Яндекс Карты</a>
                <iframe title="map-frame"
                    src="https://yandex.ru/map-widget/v1/?ll=30.300871%2C59.851457&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQxNTM0NRJV0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0JvQtdC90LjQvdGB0LrQuNC5INC_0YDQvtGB0L_QtdC60YIsIDE1MSIKDWto8kEV22dvQg%2C%2C&z=16.98"
                    allowFullScreen="true"></iframe>
            </div>
        </div>
    );
}

export default ContactsMap;