import React from 'react';
import './EngineeringDesign.css';

export const EngineeringDesign = ({background, title, children, sidebar}) => {
    return (
        <div className="engineer-container">
            <div className={background}>
                <main className="page-top">
                    <h1 className="page-title">{title}</h1>
                </main>
            </div>
            <main className="max-width">
                <div className="engineer-content-container">
                    <div className="engineer-content right_block">
                        {children}
                    </div>
                    {sidebar}
                </div>
            </main>
        </div>
    );
};