import React from 'react';
import './Service.css';
import {Link} from "react-router-dom";

const Service = ({imagePath, title, path}) => {
    return (
        <div className="service">
            <Link to={path}>
                <div className="service-title-container">
                    <h3>{title}</h3>
                </div>
                <div className="service-image-container">
                    <div className="service-image-shadow"></div>
                    <img src={imagePath} alt={title}/>
                </div>
            </Link>
        </div>
    );
};

export default Service;