import React from "react";

export const ElectricalMontage = () => {
    return (
        <div className="content">
            <h1>Монтаж электрики</h1>
            <p>Правильный монтаж электрических систем - это основа безопасности и комфорта в вашем доме или офисе.
                Качественная установка электрики обеспечивает надежное энергоснабжение, предотвращает аварийные ситуации
                и способствует эффективному использованию электроэнергии.</p>

            <h2>Наши услуги</h2>
            <p>Мы предлагаем полный комплекс услуг по монтажу электрики:</p>
            <ul>
                <li>Проектирование электрических систем: Разработка индивидуального проекта электроснабжения с учетом
                    особенностей объекта и требований клиента.
                </li>
                <li>Подбор оборудования и материалов: Помощь в выборе кабелей, автоматов, розеток, выключателей и других
                    компонентов системы от ведущих производителей.
                </li>
                <li>Монтаж электропроводки: Установка кабелей, электрических щитов, розеток, выключателей,
                    осветительных приборов и других элементов системы электроснабжения.
                </li>
                <li>Пуско-наладочные работы: Проверка и настройка системы для обеспечения ее безопасной и
                    эффективной работы.
                </li>
                <li>Техническое обслуживание: Регулярное обслуживание электрических систем для предотвращения
                    неисправностей и поддержания их надежности.
                </li>
            </ul>

            <div className="image-container">
                <img src="/images/electrical-blueprint.png" alt="Electrical Blueprint" className="image"/>
            </div>

            <h2>Преимущества наших услуг</h2>
            <ul>
                <li>Профессионализм: Наши специалисты имеют многолетний опыт и высокую квалификацию в монтаже
                    электрических систем.
                </li>
                <li>Индивидуальный подход: Разработка проекта и подбор оборудования с учетом особенностей вашего
                    объекта и ваших потребностей.
                </li>
                <li>Качество и безопасность: Использование сертифицированных материалов и оборудования от ведущих
                    производителей.
                </li>
                <li>Энергоэффективность: Установка систем, обеспечивающих максимальную эффективность и экономичность.
                </li>
                <li>Комплексное обслуживание: Полный цикл услуг от проектирования до технического обслуживания.</li>
            </ul>

            <h2>Процесс монтажа электрики</h2>
            <ul>
                <li>Консультация и оценка объекта: Наш специалист проводит осмотр объекта и обсуждает с клиентом все
                    требования и пожелания.
                </li>
                <li> Проектирование системы: Разработка детального проекта системы электроснабжения с учетом всех
                    технических и экономических аспектов.
                </li>
                <li> Подбор оборудования и материалов: Рекомендации по выбору оборудования и материалов, которые
                    наилучшим образом соответствуют потребностям клиента.
                </li>
                <li> Монтажные работы: Установка кабелей, электрических щитов, розеток, выключателей, осветительных
                    приборов и других элементов системы электроснабжения.
                </li>
                <li> Пуско-наладочные работы: Проверка системы на работоспособность и безопасность, настройка параметров
                    для оптимальной работы.
                </li>
                <li> Сдача объекта: Передача системы электроснабжения в эксплуатацию с предоставлением всех необходимых
                    документов и инструкций.
                </li>
                <li> Техническое обслуживание: Регулярные проверки и обслуживание системы для поддержания ее
                    эффективности и надежности.
                </li>

                <li>Заключительный отчет: Предоставление клиенту отчета о проделанных работах и рекомендаций по
                    дальнейшей эксплуатации.
                </li>
            </ul>

            <h2>Почему выбирают нас?</h2>
            <ul>
                <li>Опыт и надежность: Более 10 лет успешной работы в сфере монтажа электрики.</li>
                <li>Высокое качество: Использование только сертифицированных материалов и оборудования.</li>
                <li>Комплексный подход: Полный цикл услуг от проектирования до обслуживания.</li>
                <li>Индивидуальный подход: Учет всех пожеланий клиента и особенностей объекта.</li>
                <li>Конкурентные цены: Прозрачное ценообразование и доступные тарифы на услуги.</li>
            </ul>
        </div>
    );
}
