import React from "react";

const Ventilation = () => {
    return (
        <div className="content">
            <h1>Проектирование систем вентиляции</h1>
            <p>Эффективные системы вентиляции обеспечивают комфортные условия для работы и проживания, способствуют поддержанию оптимального микроклимата и предотвращают накопление вредных веществ в воздухе. Наши специалисты обладают необходимыми знаниями и опытом для разработки надежных систем вентиляции для любых объектов.</p>
            <p>Мы предоставляем полный комплекс услуг по проектированию систем вентиляции для жилых, коммерческих и промышленных объектов. Наши проекты учитывают все требования и нормы, обеспечивая высокий уровень качества и надежности.</p>

            <h2>Что включает проектирование систем вентиляции</h2>
            <p>Наши услуги по проектированию систем вентиляции включают в себя:</p>
            <ul>
                <li>Проведение анализа условий эксплуатации и требований заказчика.</li>
                <li>Разработка концепции и схем вентиляции.</li>
                <li>Выбор вентиляционного оборудования и материалов.</li>
                <li>Подготовка проектной документации и ее согласование.</li>
                <li>Проведение расчетов аэродинамики и выбор воздуховодов.</li>
            </ul>
            <p>Мы предоставляем услуги проектирования систем вентиляции для различных типов объектов, учитывая их уникальные особенности и требования. Наши проекты отличаются высокой эффективностью и надежностью.</p>
            <div className="image-container">
                <img src="/images/ventilation-blueprint.png" alt="Blueprint" className="image"/>
            </div>

            <h2>Этапы проектирования систем вентиляции</h2>
            <p>Процесс проектирования включает несколько ключевых этапов:</p>
            <ul>
                <li>Исследование и анализ условий эксплуатации объекта.</li>
                <li>Разработка концепции и схем вентиляции.</li>
                <li>Выбор оборудования и материалов для вентиляции.</li>
                <li>Согласование проектной документации с контролирующими органами.</li>
                <li>Подготовка окончательной документации и сдача проекта.</li>
            </ul>
            <p>Стоимость услуг по проектированию систем вентиляции зависит от объема и сложности работ. Для получения подробной информации и расчета стоимости проекта, свяжитесь с нами по телефону или электронной почте.</p>
        </div>
    );
}

export default Ventilation;
