import React from "react";

function BoilerData() {
    return (
        <div className="content">
            <h1>Обслуживание и ремонт котлов </h1>
            <p>Обслуживание и ремонт котлов - это ключевые услуги, которые обеспечивают надежную и безопасную работу вашей отопительной системы. Регулярное техническое обслуживание и своевременный ремонт позволяют продлить срок службы котла, снизить затраты на энергопотребление и избежать аварийных ситуаций.</p>

            <h2>Наши услуги</h2>
            <p>Мы предлагаем комплексные услуги по обслуживанию и ремонту котлов, включая:</p>
            <ul>
                <li>Диагностика и профилактика: Тщательная проверка всех компонентов котла, выявление и устранение потенциальных проблем.</li>
                <li>Чистка и настройка: Удаление накипи и загрязнений, настройка котла для оптимальной работы</li>
                <li>Ремонт: Быстрое и качественное устранение неисправностей с использованием оригинальных запчастей.</li>
                <li>Плановое обслуживание: Регулярные проверки и обслуживание для предотвращения поломок и поддержания высокой эффективности работы котла.</li>
                <li>Консультации: Профессиональные советы по эксплуатации и обслуживанию котлов.</li>
            </ul>
            <h2>Преимущества наших услуг</h2>
            <ul>
                <li>Профессионализм: Наши специалисты имеют многолетний опыт работы и высокую квалификацию.</li>
                <li>Качество: Мы используем только качественные материалы и оборудование.</li>
                <li>Оперативность: Быстрое реагирование на заявки и выполнение работ в кратчайшие сроки.</li>
                <li>Гарантия: Предоставляем гарантию на все виды выполненных работ.</li>
                <li>Индивидуальный подход: Учитываем особенности каждого котла и требования клиента.</li>
            </ul>

            <h2>Процесс обслуживания и ремонта</h2>
            <ul>
                <li>Первичная консультация: Определение проблемы и консультирование по возможным решениям.</li>
                <li>Диагностика: Комплексная проверка состояния котла.</li>
                <li>Составление сметы: Определение стоимости работ и согласование с клиентом.</li>
                <li>Выполнение работ: Проведение всех необходимых работ по обслуживанию или ремонту.</li>
                <li>Проверка и настройка: Финальная проверка работоспособности котла и его настройка для оптимальной работы.</li>
                <li>Заключительный отчет: Предоставление клиенту отчета о проделанных работах и рекомендаций по дальнейшей эксплуатации.</li>
            </ul>

            <h2>Почему выбирают нас?</h2>
            <ul>
                <li>Опыт и компетентность: Мы успешно работаем на рынке уже более 10 лет.</li>
                <li>Комплексный подход: Выполняем весь спектр услуг по обслуживанию и ремонту котлов.</li>
                <li>Доступные цены: Конкурентные цены при высоком качестве услуг.</li>
                <li>Клиентоориентированность: Индивидуальный подход и внимательное отношение к каждому клиенту.</li>
            </ul>
            <h2>Наши сертификаты</h2>
            <div className="cert-image-container">
                <img src="/images/cert-images/Головачев.jpg" alt="Cert-image" className="image-cert"/>
                <img src="/images/cert-images/Т-Монтаж.jpg" alt="Cert-image" className="image-cert"/>
                <img src="/images/cert-images/cert_one.jpeg" alt="Cert-image" className="image-cert"/>
                <img src="/images/cert-images/cert_two.jpeg" alt="Cert-image" className="image-cert"/>
            </div>
        </div>
    );
}

export default BoilerData;