import React from "react";
import {Link} from "react-router-dom";

function ContactsData() {
    return (
        <div className="content contact-data">
            <h1>Контакты:</h1>
            <p>Наш офис: <Link to="https://yandex.ru/maps/?from=mapframe&ll=30.300983%2C59.851422&poi%5Bpoint%5D=30.300983&poi%5Bpoint%5D=59.851422&poi%5Buri%5D=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQxNTM0NRJV0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0JvQtdC90LjQvdGB0LrQuNC5INC_0YDQvtGB0L_QtdC60YIsIDE1MSIKDWto8kEV22dvQg%2C%2C&source=mapframe&utm_source=mapframe&z=16">Ленинский пр-т д.151 офис 713.</Link></p>
            <p>Телефон: <Link to="tel:+79119266734">8 (911) 926-67-34</Link></p>
            <p>Почта: <Link to="mailto:spb@tmontazh.ru">spb@tmontazh.ru</Link></p>
        </div>
    );
}

export default ContactsData;