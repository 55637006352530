import React from 'react';
import './Sidebar.css';
import {Link} from "react-router-dom";

export const Sidebar = ({items}) => {
    return (
        <div className="left_block">
            <div className="sidebar">
                {items.map((item, index) => {
                    return <Link key={index} to={item.path}>
                        <div className="sidebar-item">
                            {item.title}
                        </div>
                    </Link>;
                })}
            </div>
        </div>
    );
};