
export const engineerItems = [
    {
        title: 'Проектирование инженерных систем',
        path: '/engineering-system'
    },
    {
        title: 'Проектирование электроснабжения',
        path: '/electrical-system'
    },
    {
        title: 'Проектирование систем вентиляции',
        path: '/ventilation-system'
    }
]

export const montageItems = [
    {
        title: 'Отопление',
        path: '/heating'
    },
    {
        title: 'Водоснабжение и канализация',
        path: '/water-supply'
    },
    {
        title: 'Электрика',
        path: '/electrician'
    },
    {
        title: 'Вентиляция',
        path: '/ventilation'
    }
]