import React from "react";

const Engineering = () => {
    return (
        <div className="content">
            <h1>Проектирование инженерных систем</h1>
            <p>Качественное проектирование инженерных систем, выполненное профессионалами – основа успеха в дальнейшей реализации проекта. Только руководствуясь проектной документацией, можно выполнять монтаж коммуникаций и сетей, обеспечивая им надежность и соответствие стандартам.</p>
            <p>Наша компания выполняет проектирование инженерных сетей для различных объектов Санкт-Петербурга. В разработке проектов задействованы все разделы примыкающих и сопутствующих инженерий. Кроме того, наше предприятие берется как за проекты на монтаж с нуля, так и на работы по монтажу и подключению – поэтому стремиться к работе сторонних организаций не придется.</p>

            <h2>Проектирование инженерных систем: что включает</h2>
            <p>Проектирование инженерных систем выполняется на этапе подготовки к монтажу коммуникаций. При этом проектирование одинаково нужно как для внутренних, так и для наружных частей всех систем объекта. Мы проектируем:</p>
            <ul>
                <li>Тепловые узлы.</li>
                <li>Трубопроводы, водоснабжение внутреннее, наружное, для холодоснабжения.</li>
                <li>Водосточные трубопроводы с оборудованием для охлаждения сточных, из утилизированных вод и аналогичное оборудование.</li>
                <li>Канализацию.</li>
            </ul>
            <p>Перечень работ зависит от поставленных задач. Если нужно, выполняем разработку пакета документов, необходимого для сдачи объекта. Также можем оказать услуги на выполнении отдельных этапов работ – например, отопление, водопровод или канализация.</p>
            <div className="image-container">
                <img src="/images/engineering-blueprint.png" alt="Blueprint" className="image"/>
            </div>
            <h2>Этапы проектирования</h2>
            <p>Работа начинается со старта заказа. На этом этапе утверждается концепция, а также согласовываются детали, необходимые для дальнейшего расчета и постоянной сметы. Кроме того, перед началом основных работ выполняется предварительное разрешительное проектирование, выдающее разрешительную документацию (ППД), включающую технические задания, а также технические условия для дальнейших этапов.</p>
            <p>На основании анализа утвержденного расчета и ППД ведется проектирование. При этом:</p>
            <ul>
                <li>Выполняются все необходимые расчеты и обоснования.</li>
                <li>Составляется схема прокладки трубопроводов, других коммуникаций.</li>
                <li>Готовится пояснительная записка.</li>
            </ul>
            <p>Готовый проект согласовывается в контролирующих органах. Процедура согласования защищает объект от лишних проверок в дальнейшем и позволяет получить все нужные спецификации. Во втором случае заказчик проверяет исходные данные документации на соответствие контрольным измерениям и может утверждать проект, исключая ошибки.</p>
            <p>На этом же этапе составляется смета на выполнение работ. Подробный расчет всех операций позволяет сделать смету максимально точной, поэтому проведение сметы на этапе монтажа коммуникаций не требует практической корректировки.</p>

            <h2>Заказ проекта инженерных сетей</h2>
            <p>Проектирование инженерной сети жилого, коммерческого или производственного объекта в Санкт-Петербурге можно заказать в нашей компании. Мы:</p>
            <ul>
                <li>Много лет работаем в отрасли, поэтому благодаря большому опыту можем разрабатывать проекты различной сложности.</li>
                <li>Реально обеспечиваем нужные бюджеты (гибкие, плавающие), учитываются все нюансы.</li>
                <li>Обеспечиваем готовыми проектами максимально быстро.</li>
                <li>Соблюдаем требования законодательства, что упрощает согласование.</li>
            </ul>
            <p>Наша компания чересчур вовлечена в проектирование всех сетей для вашего объекта и способна сделать быстро. У вас на руках будет полный пакет проверенной и рабочей документации, который можно использовать при выполнении монтажных работ.</p>
        </div>
    );
}

export default Engineering;