import Dropdown from '../dropdown/index';
import {NavLink, useMatch, useResolvedPath} from "react-router-dom";
import {useState} from "react";
import {MdArrowDropDown} from "react-icons/md";

export const menuItemsData = [
    {
        title: 'Главная',
        path: '/'
    },
    {
        title: 'Проектирование',
        submenu: [
            {
                title: 'Проектирование инженерных систем',
                path: '/engineering-system'
            },
            {
                title: 'Проектирование электроснабжения',
                path: '/electrical-system'
            },
            {
                title: 'Проектирование систем вентиляции',
                path: '/ventilation-system'
            }
        ]
    },
    {
        title: 'Монтаж',
        submenu: [
            {
                title: 'Отопление',
                path: '/heating'
            },
            {
                title: 'Водоснабжение и канализация',
                path: '/water-supply'
            },
            {
                title: 'Электрика',
                path: '/electrician'
            },
            {
                title: 'Вентиляция',
                path: '/ventilation'
            }
        ]
    },
    {
        title: 'Обслуживание и ремонт котлов',
        path: '/boiler'
    },
    {
        title: 'Контакты',
        path: '/contacts'
    }
]

const MenuItems = ({ items, closeMobileMenu }) => {
    const resolvedPath = useResolvedPath(items.path)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    const [dropdown, setDropdown] = useState(false);

    const toggleDropdown = () => setDropdown(!dropdown);

    return (
        <>
            {items.submenu ? (
                <li className="nav-item"
                    onMouseEnter={toggleDropdown}
                    onMouseLeave={toggleDropdown}
                    onClick={toggleDropdown}>
                    <span className="nav-links" aria-haspopup="menu">
                        {items.title}{' '} <MdArrowDropDown />
                    </span>
                    {dropdown && (
                        <Dropdown submenus={items.submenu} closeMobileMenu={closeMobileMenu} />
                    )}
                    {/*<Dropdown submenus={items.submenu} />*/}
                </li>
            ) : (
                <li className={isActive ? "active nav-item" : "nav-item"} key={items.index} onClick={closeMobileMenu}>
                    <NavLink className="nav-links" to={items.path}>{items.title}</NavLink>
                </li>
            )}
        </>
    );
};

export default MenuItems;