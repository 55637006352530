import React from 'react';
import './SpecialistChoice.css';

const SpecialistChoice = () => {
    return (
        <div>
            <div className="choice-container">
                <div className="specialist-choice">
                    <div className="choice-content content">
                        <p>На сегодняшний день огромное количество людей мечтает построить загородный дом для постоянного проживания или дачу для выезда на отдых по выходным.</p>
                        <p>Так или иначе, осуществив свою мечту, вы приходите к выбору исполнителя по монтажу системы отопления, водоснабжения и канализации. Тут есть три варианта.</p>
                    </div>
                    <div className="image-container">
                        <img src="/images/specialistChoosing.png" alt="Выбор специалиста" />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SpecialistChoice;